html,
body {
  margin: 0;
  max-width: 100%;
  min-width: 500px;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.members-table .ant-table-cell {
  height: 54px !important;
  padding: 3px 16px;
}

table > thead.ant-table-thead > tr > th.ant-table-cell:before {
  display: none !important;
}
.ant-table-header {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.tableSpinner {
  position: relative;
}

.ant-picker {
  box-shadow: none !important;
}

.ant-table {
  background: #f0f0f0;
  border: 0px solid #f0f0f0;
  border-radius: 8px !important;
  filter: drop-shadow(0px 6px 24px rgba(0, 0, 0, 0.05)) !important;
}

.ant-table-placeholder:hover {
  border-radius: 8px !important;
}

.ant-table-placeholder:hover > .ant-table-cell {
  border-radius: 8px !important;
}

.ant-table-placeholder > .ant-table-cell {
  border-radius: 8px !important;
}

.ant-table-row {
  background-color: white !important;
}

.ant-table-row:last-child {
  border-radius: 8px !important;
}

.ant-table-row:last-child > .ant-table-cell:first-child {
  border-bottom-left-radius: 8px !important;
}

.ant-table-row:last-child > .ant-table-cell:last-child {
  border-bottom-right-radius: 8px !important;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 8px !important;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 8px !important;
}

.ant-table-thead > tr > th {
  font-weight: 800 !important;
}

.ant-alert {
  font-family: "Rubik" !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #262626 !important;
}

.ant-alert-warning {
  border-radius: 4px !important;
  border: 1px solid #fed18d !important;
  background-color: #fff9f0 !important;
}

.ant-alert-success {
  background-color: #f0faf6 !important;
  border: 1px solid #69c9a3 !important;
}

.ant-alert > .ant-alert-icon {
  margin-right: 10px !important;
}

.ant-alert-success > .ant-alert-icon {
  color: #03a564 !important;
}

.ant-alert-warning > .ant-alert-icon {
  margin-right: 10px;
}

th.ant-table-cell,
td.ant-table-cell {
  color: #8c8c8c;
  font-family: "Rubik", serif;
  text-align: center !important;
}

td.ant-table-cell:not(:last-child),
th.ant-table-cell:not(:last-child) {
  border-right-width: 0 !important;
}

tr.ant-table-expanded-row > td.ant-table-cell {
  background: none;
  padding: 7px 20px;
}

.ant-table-content > table > tbody {
  border-radius: 8px;
}

.ant-table-tbody > tr:last-child > td {
  border-width: 1px 1px 0 1px;
}

.ant-modal-content {
  border: 1px solid #bfbfbf !important;
  box-shadow: 0 100px 80px rgba(108, 73, 172, 0.07), 0 42px 33px rgba(108, 73, 172, 0.0503198), 0 22px 18px rgba(108, 73, 172, 0.0417275),
    0 13px 10px rgba(108, 73, 172, 0.035), 0 7px 5px rgba(108, 73, 172, 0.0282725), 0 3px 2px rgba(108, 73, 172, 0.0196802) !important;
  border-radius: 24px !important;
}

.ant-modal-body {
  padding: 60px !important;
}

@media (max-width: 823px) {
  .ant-modal-body {
    padding: 30px !important;
  }
}

.ant-input-prefix {
  color: #cdb8f0 !important;
  margin-right: 8px !important;
}

.ant-input {
  font-size: 16px !important;
  line-height: 19px !important;
  font-family: "Rubik" !important;
  height: 25px;
  border: none !important;
  color: #8c8c8c !important;
}

.ant-input:hover,
.ant-input:focus {
  border-color: #8c5bdc;
  box-shadow: none !important;
}

.ant-input-search {
  border: 1px solid #d9d9d9;
  font-family: "Rubik";
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  transition: border 0.5s linear;
}

.ant-input-search .ant-input {
  height: 51px !important;
}

.ant-input-search .ant-btn {
  height: 51px !important;
}

.ant-input-search:hover {
  border-color: #a37ce3 !important;
}

.ant-btn {
  border: 1px solid transparent !important;
  height: 35px !important;
  width: 35px !important;
  box-shadow: none !important;
}

.anticon-search {
  color: #8c5bdc !important;
}

.ant-input::placeholder {
  color: #8c8c8c !important;
}

.ant-input-affix-wrapper {
  padding: 12px 20px !important;

  border: 1px solid #d9d9d9 !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04) !important;
  border-radius: 8px !important;
}

.ant-input-affix-wrapper-focused {
  border: 1px solid #8c5bdc !important;
  box-shadow: 0 0 6px rgba(140, 91, 220, 0.4) !important;
}

.ant-input-affix-wrapper-focused .anticon > svg > path {
  fill: #8c5bdc !important;
  transition: fill 0.3s ease;
}

.ant-radio-button-wrapper {
  box-shadow: none !important;
  border-color: #d9d9d9 !important;
  border-style: solid;
  border-width: 1px 0 1px 1px !important;
}

.ant-radio-button-wrapper-checked {
  color: #8c5bdc !important;
  border: 1px solid #8c5bdc !important;
}

.ant-radio-button-wrapper:hover {
  color: #8c5bdc !important;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper:hover {
  color: rgba(0, 0, 0, 0.25) !important;
}
.ant-radio-button-wrapper::before {
  background-color: transparent !important;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 4px 0 0 4px !important;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0 !important;
  border-width: 1px 1px 1px 1px !important;
}

.ant-radio-button-wrapper-checked + .ant-radio-button-wrapper {
  border-left-width: 0px !important;
}

.ant-picker {
  border-radius: 4px !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
}

.ant-picker:hover {
  border: 1px solid #8c5bdc !important;
}

.ant-picker-focused {
  border: 1px solid #8c5bdc !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04) !important;
}

.ant-picker-input > input {
  font-family: "Rubik", serif;
  font-size: 14px;
  color: #262626 !important;
}

.ant-picker-input > input::placeholder {
  color: #262626 !important;
}

.ant-picker-input-active {
  border: none !important;
}

.ant-picker-active-bar {
  display: none;
}

/* ANT PICKER CELL/RANGE STYLES */
.ant-picker-cell-inner {
  font-family: "Rubik";
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #8c5bdc !important;
  border: none !important;
  border-radius: 2px 2px 2px 2px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #fcfbfe !important;
}
/*-------------------------------*/

.ant-checkbox-wrapper {
  color: #262626 !important;
}

.ant-checkbox-inner {
  border-color: #d9d9d9 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #8c5bdc !important;
  background-color: #8c5bdc !important;
}

.creditCardInputImage {
  display: none;
}
.creditCardInput {
  padding: 16px 20px 16px 46px;
  position: static !important;
  border: 1px solid transparent !important;

  font-family: "Rubik", serif !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #8c8c8c;
}

.creditCardInput::placeholder {
  color: #8c8c8c;
}

.creditCardInput:focus {
  border: 1px solid #8c5bdc !important;
  box-shadow: 0 0 6px rgba(140, 91, 220, 0.4);
}

.creditCardField {
  display: flex !important;
  padding: 0 !important;
  border: 1px solid #d9d9d9 !important; /* remove important here to set red border on error */
  border-radius: 8px !important;
}

.creditCardField input {
  height: 51px;
}

.creditCardField > label {
  flex-grow: 1;
  height: 51px;
  padding: 0 !important;
  margin: 0 !important;
}

.creditCardField > label::after {
  display: none !important;
}

.creditCardField > label > #card-number {
  flex-shrink: 1;
  background: url("./assets/images/card-icon.svg") no-repeat 20px center;
  border-radius: 7px 0 0 7px !important;
}

.creditCardField > label > #card-expiry {
  flex-shrink: 2;
  background: url("./assets/images/calendar-icon.svg") no-repeat 20px center;
}

.creditCardField > label > #cvc {
  flex-shrink: 2;
  background: url("./assets/images/cvc-icon.svg") no-repeat 20px center;
  border-radius: 0 7px 7px 0 !important;
}

.creditCardContainer {
  display: block !important;
}

/* ANTD - TAG */
.site-tag-plus {
  margin-right: 0 !important;

  background: #ffffff !important;
  border: 1px dashed #d9d9d9 !important;
  border-radius: 4px !important;

  color: #262626 !important;
}

.edit-tag {
  user-select: none;
  width: max-content;
  margin-right: 0 !important;

  background: inherit !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 4px !important;

  font-size: 14px !important;
  color: #262626 !important;
}

.tag-input {
  display: inline !important;
  max-width: max-content !important;
  height: 22px !important;
  vertical-align: top;

  font-size: 14px !important;
  color: #262626 !important;
}
/* ---------- */

/* ANTD-MEMBER-ACCESS-SELECT */
.member-access-select {
  height: 32px;
  border: none !important;
  box-shadow: none !important;
}

.member-access-select--table {
  width: 100%;
  max-width: 150px;
  padding: 0 10px !important;
  border: 1px solid #d9d9d9 !important;
  text-align: left;
}

.member-access-select--bordered > .ant-select-selector {
  height: 32px !important;
}

.member-access-select > .ant-select-selector {
  border: none !important;
  padding: 3px 12px 3px 6px !important;
}

.member-access-select.ant-select-open {
  box-shadow: none !important;
  border: none !important;
}

.member-access-select > .ant-select-selector > .ant-select-selection-item {
  line-height: 25px !important;
}
/* ------------------------- */

.ant-dropdown-menu {
  padding: 0 !important;
}

.edit-profile {
  transition: all 0.5s linear;
}

.edit-profile:hover {
  background: none !important;
  color: #8c5bdc;
}

.default-middle-modal > .ant-modal {
  max-width: 425px;
}

.default-middle-modal .ant-modal-title {
  font-family: Rubik;
  font-size: 18px;
  color: #595959;
}

.default-middle-modal .ant-modal-header {
  border-bottom-width: 0;
  border-radius: 20px;
  padding: 0 !important;
}

.default-middle-modal .ant-modal-body {
  padding: 24px 0 0 0 !important;
}

.default-middle-modal .ant-modal-close {
  top: 27px !important;
  right: 27px !important;
}

.default-middle-modal .ant-modal-content {
  border-radius: 20px !important;
  padding: 48px;
  border-width: 0px !important;
}

.ant-modal-mask {
  background-color: rgba(256, 256, 256, 0.7) !important;
}

.country-select {
  height: 51px;
  padding: 0 40px;
  position: relative;
  width: 100%;

  font-family: "Rubik", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  color: #8c8c8c;

  background: url("./assets/images/country.svg") no-repeat 20px center;
  background-size: 18px;
  border-style: solid;
  border-width: 1px 0 1px 1px;
  border-color: #d6d6d6 !important;
  border-radius: 8px 0 0 8px !important;
  outline: none;

  -webkit-appearance: none;
}

@media screen and (max-width: 1199px) {
  .country-select {
    border-radius: 8px !important;
    border: 1px solid #d6d6d6 !important;
  }
}

.country-select::placeholder {
  color: #8c8c8c;
}

.country-select:focus {
  border: 1px solid #8c5bdc;
}

.country-select > .ant-select-selector > .ant-select-selection-item {
  padding: 0 40px !important;

  font-family: "Rubik", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 34px !important;
  color: #8c8c8c;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: blueviolet !important;
}

.ant-picker-cell-inner:hover {
  background: #8c5bdc !important;
  color: white !important;
}

.multiselect-dropdown {
  border-radius: 6px !important;
}

.multiselect-dropdown .ant-divider {
  border-bottom: 1px solid #d9d9d9 !important;
  margin: 0 15px;
}

.multiselect-dropdown .ant-select-item {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.multiselect-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 400;
  background-color: transparent;
}

.multiselect-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: transparent;
}

.fingerprint-lists-table {
  user-select: none;
}

.table-without-shadow .ant-table {
  filter: none !important;
}

.table-with-border .ant-table {
  border: 1px solid #f0f0f0;
}

.table-with-border .ant-table-container:before,
.table-with-border .ant-table-body {
  border-radius: 8px !important;
}

.table-with-border table {
  border-radius: 8px !important;
}

.table-with-upper-block table {
  background-color: #fafafa !important;
}

.table-with-upper-block .ant-table > .ant-table-container > .ant-table-body > table > .ant-table-tbody > tr > .ant-table-cell {
  border-top: 1px solid #f0f0f0 !important;
  border-bottom: none !important;
}

.table-with-upper-block td {
  padding: 9px 16px !important;
}

.table-with-upper-block > .ant-spin-nested-loading > .ant-spin-container > .ant-table > .ant-table-container > .ant-table-body > table > .ant-table-summary {
  display: table-header-group !important;
}

.custom-rule-upper-table .ant-table-row:last-child {
  border-radius: 0 !important;
}
.custom-rule-upper-table td {
  padding: 9px 16px !important;
}
.custom-rule-upper-table .ant-table-row:last-child > .ant-table-cell {
  border-radius: 0 !important;
}
.custom-rule-lower-table table > thead.ant-table-thead > tr:first-child th {
  border-radius: 0 !important;
}
.custom-rule-lower-table tbody tr:first-child, .custom-rule-upper-table tbody tr:first-child {
  position: absolute;
}
.custom-rule-lower-table table {
  border-top: 1px solid #f0f0f0 !important;
}
.custom-rule-lower-table .ant-table-tbody > tr > td {
  border: none;
}

.custom-rule-lower-table td {
  padding: 9px 16px !important;
}

.custom-rule-lower-table .ant-table-container .ant-table-content {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.custom-rule-lower-table .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  border-bottom-right-radius: 8px;
}

.selected-row {
  cursor: pointer;
}

.rule-editor-textarea {
  border-radius: 16px;
}

.keyri-switch.ant-switch-checked {
  background-color: #8c5bdc !important;
}

.cm-activeLine.cm-line, .cm-activeLineGutter {
    background-color: #CDB8F0 !important;
}
