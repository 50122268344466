/* This stylesheet generated by Transfonter (https://transfonter.org) on October 5, 2017 10:21 PM */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

@font-face {
	font-family: 'FuturaDemiC';
	src: url('FuturaDemiC.eot');
	src: local('Futura Demi Cyrillic'), local('FuturaDemiC'),
		url('FuturaDemiC.eot?#iefix') format('embedded-opentype'),
		url('FuturaDemiC.woff') format('woff'),
		url('FuturaDemiC.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'FuturaMediumC';
	src: url('FuturaMediumC.eot');
	src: local('Futura Medium Cyrillic'), local('FuturaMediumC'),
		url('FuturaMediumC.eot?#iefix') format('embedded-opentype'),
		url('FuturaMediumC.woff') format('woff'),
		url('FuturaMediumC.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'FuturaLightC';
	src: url('FuturaLightC-Italic.eot');
	src: local('Futura Light Italic Cyrillic'), local('FuturaLightC-Italic'),
		url('FuturaLightC-Italic.eot?#iefix') format('embedded-opentype'),
		url('FuturaLightC-Italic.woff') format('woff'),
		url('FuturaLightC-Italic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaMediumC';
	src: url('FuturaMediumC-Italic.eot');
	src: local('Futura Medium Italic Cyrillic'), local('FuturaMediumC-Italic'),
		url('FuturaMediumC-Italic.eot?#iefix') format('embedded-opentype'),
		url('FuturaMediumC-Italic.woff') format('woff'),
		url('FuturaMediumC-Italic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaLightC';
	src: url('FuturaLightC.eot');
	src: local('Futura Light Cyrillic'), local('FuturaLightC'),
		url('FuturaLightC.eot?#iefix') format('embedded-opentype'),
		url('FuturaLightC.woff') format('woff'),
		url('FuturaLightC.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'FuturaDemiC';
	src: url('FuturaDemiC-Italic.eot');
	src: local('Futura Demi Italic Cyrillic'), local('FuturaDemiC-Italic'),
		url('FuturaDemiC-Italic.eot?#iefix') format('embedded-opentype'),
		url('FuturaDemiC-Italic.woff') format('woff'),
		url('FuturaDemiC-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaBookC';
	src: url('FuturaBookC-Italic.eot');
	src: local('Futura Book Italic Cyrillic'), local('FuturaBookC-Italic'),
		url('FuturaBookC-Italic.eot?#iefix') format('embedded-opentype'),
		url('FuturaBookC-Italic.woff') format('woff'),
		url('FuturaBookC-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaBookC';
	src: url('FuturaBookC.eot');
	src: local('Futura Book Cyrillic'), local('FuturaBookC'),
		url('FuturaBookC.eot?#iefix') format('embedded-opentype'),
		url('FuturaBookC.woff') format('woff'),
		url('FuturaBookC.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url('Rubik-Regular.ttf');
}

@font-face {
	font-family: 'Rubik-Light';
	src: url('Rubik-Light.ttf');
}

@font-face {
	font-family: 'Rubik-SemiBold';
	src: url('Rubik-SemiBold.ttf');
}

@font-face {
	font-family: 'Rubik-ExtraBold';
	src: url('Rubik-ExtraBold.ttf');
}

@font-face {
	font-family: 'Rubik-Bold';
	src: url('Rubik-Bold.ttf');
}

@font-face {
	font-family: 'Miriam Libre';
	src: url('MiriamLibre-Regular.ttf');
}
