.ant-select {
    border: 1px solid #f0f0f0;
    border-radius: 8px;

    font-family: "Rubik", serif;
}

.ant-select-open {
    border: 1px solid #8c5bdc !important;
    box-shadow: 0 0 6px rgba(140, 91, 220, 0.4) !important;
    border-radius: 8px;
}

.ant-select-selector {
    padding: 6px 12px 6px 6px !important;
    height: 45px !important;
    background-color: transparent !important;
    border: 1px solid #f0f0f0 !important;
    border-radius: 8px;
}