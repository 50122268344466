.menuItem {
    color: #FFF;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 19px;
    user-select: none;
}

.menuItem:last-child {
    margin-bottom: 0px;
}

.menuLink {
    text-decoration: none;
    color: #595959;
}

.menuItemStyle {
    padding: 0 0 0 14px;
    height: 45px;
    width: 100%;

    display: flex;
    align-items: center;
    gap: 10px;

    font-family: "Rubik";
    color: #595959;

    border: 1px solid transparent;
    background: transparent;
    border-radius: 6px;

    cursor: pointer;
    transition: all 0.3s ease;
}

.menuItemStyle:hover {
    color: #8C5BDC;
    border: 1px solid #CDB8F0;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
}
