td.table-title-cell-left-align {
    text-align: left !important;
}

.fingerprint-lists-table-summary .ant-checkbox-wrapper {
    color: #8C8C8C !important;
}

.fingerprint-lists-table-summary {

}